<template>
  <div>
    <b-card>
      <template #footer>
        <b-button
          type="submit"
          variant="primary"
          @click="onSubmit"
        >
          Submit
        </b-button>
        <b-button
          class="ml-1"
          type="reset"
          variant="secondary"
          @click="cancelForm"
        >
          Cancel
        </b-button>
      </template>
      <div class="d-flex justify-content-between align-items-center mb-2">
        <h4>{{ formTitle }}</h4>
        <b-dropdown
          v-if="formMode === 'create'"
          text="Load Template"
          size="sm"
          lazy
          right
          @show="fetchTemplate"
        >
          <template v-if="templateListOpts && templateListOpts.length > 0">
            <template v-for="(plan, idx) in templateListOpts">
              <b-dropdown-item
                :key="`dd-${idx}`"
                @click="loadTemplate(plan.id)"
              >
                {{ plan.planName }}
              </b-dropdown-item>
            </template>
          </template>
          <template v-else>
            <b-dropdown-item> Loading template... </b-dropdown-item>
          </template>

        </b-dropdown>
      </div>
      <b-row>
        <b-col>
          <div class="pb-2">
            <b-row>
              <b-col>
                <b-form-group :class="[{ 'has-error': v$.actualForm.plan_name.$error }]">
                  <label>Plan Name</label>
                  <b-form-input
                    v-model="actualForm.plan_name"
                    size="sm"
                  />
                  <template v-for="(error, idx) in v$.actualForm.plan_name.$errors">
                    <p
                      :key="'error-'+idx"
                      class="small text-danger"
                    >
                      {{ error.$message }}
                    </p>
                  </template>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  :class="[{ 'has-error': v$.actualForm.plan_charges.$error }]"
                >

                  <label>Plan Charges (Monthly)</label>
                  <vue-numeric
                    v-model="actualForm.plan_charges"
                    class="form-control form-control-sm text-right"
                    :precision="2"
                    :currency="myCurrency"
                    separator=","
                  />
                  <template v-for="(error, idx) in v$.actualForm.plan_charges.$errors">
                    <p
                      :key="'error-'+idx"
                      class="small text-danger"
                    >
                      {{ error.$message }}
                    </p>
                  </template>
                </b-form-group>
              </b-col>
            </b-row>

            <b-form-group :class="[{ 'has-error': v$.actualForm.description.$error }]">
              <label>Plan Description</label>
              <b-form-textarea
                v-model="actualForm.description"
                size="sm"
              />
              <template v-for="(error, idx) in v$.actualForm.description.$errors">
                <p
                  :key="'error-'+idx"
                  class="small text-danger"
                >
                  {{ error.$message }}
                </p>
              </template>
            </b-form-group>
          </div>
        </b-col>
      </b-row>

      <div class="channels mb-2">
        <b-row>
          <b-col>
            <div class="pb-2">
              <h5 class="mb-1">
                <strong>Payment Channels</strong>
              </h5>
              <b-row class="border-bottom pt-1">
                <b-col>
                  <b-form-group>
                    <b-form-checkbox
                      v-model="actualForm.fpx_enable"
                      checked="false"
                      switch
                      inline
                    >
                      Enable FPX
                    </b-form-checkbox>
                  </b-form-group>
                </b-col>
                <b-col v-if="actualForm.fpx_enable">
                  <b-form-group
                    label="FPX Rate Type"
                    label-cols-sm="7"
                  >
                    <v-select
                      v-model="actualForm.fpx_rate_type_reference_id"
                      :clearable="false"
                      class="select-size-sm"
                      label="name"
                      :reduce="option => option.value"
                      :options="[{value: 1, name: 'Fixed'}, {value: 2, name: 'Percentage'}]"
                    />
                  </b-form-group>
                  <h5
                    class="mt-3"
                    style="font-weight: 600;"
                  >
                    B2C
                  </h5>
                  <b-form-group
                    label="FPX Charges"
                    label-cols-sm="7"
                  >
                    <vue-numeric
                      v-model="actualForm.fpx_charges"
                      class="form-control form-control-sm text-right"
                      :precision="2"
                      :currency-symbol-position="actualForm.fpx_rate_type_reference_id === 1? 'prefix': 'suffix'"
                      :currency="actualForm.fpx_rate_type_reference_id === 1? myCurrency: '%'"
                      separator=","
                    />
                    <template v-for="(error, idx) in v$.actualForm.fpx_charges.$errors">
                      <p
                        :key="'error-'+idx"
                        class="small text-danger"
                      >
                        {{ error.$message }}
                      </p>
                    </template>
                  </b-form-group>
                  <b-form-group
                    label="Threshold Charges"
                    label-cols-sm="7"
                  >
                    <vue-numeric
                      v-model="actualForm.threshold_charges"
                      class="form-control form-control-sm text-right"
                      :precision="2"
                      :currency-symbol-position="actualForm.fpx_rate_type_reference_id === 1? 'prefix': 'suffix'"
                      :currency="actualForm.fpx_rate_type_reference_id === 1? myCurrency: '%'"
                      separator=","
                    />
                    <template v-for="(error, idx) in v$.actualForm.threshold_charges.$errors">
                      <p
                        :key="'error-'+idx"
                        class="small text-danger"
                      >
                        {{ error.$message }}
                      </p>
                    </template>
                  </b-form-group>

                  <h5
                    class="mt-3"
                    style="font-weight: 600;"
                  >
                    B2B
                  </h5>
                  <b-form-group
                    label="FPX Charges"
                    label-cols-sm="7"
                  >
                    <vue-numeric
                      v-model="actualForm.fpx_b2b_charges"
                      class="form-control form-control-sm text-right"
                      :precision="2"
                      :currency-symbol-position="actualForm.fpx_rate_type_reference_id === 1? 'prefix': 'suffix'"
                      :currency="actualForm.fpx_rate_type_reference_id === 1? myCurrency: '%'"
                      separator=","
                    />
                    <template v-for="(error, idx) in v$.actualForm.fpx_b2b_charges.$errors">
                      <p
                        :key="'error-'+idx"
                        class="small text-danger"
                      >
                        {{ error.$message }}
                      </p>
                    </template>
                  </b-form-group>
                  <b-form-group
                    label="Threshold Charges"
                    label-cols-sm="7"
                  >
                    <vue-numeric
                      v-model="actualForm.b2b_threshold_charges"
                      class="form-control form-control-sm text-right"
                      :precision="2"
                      :currency-symbol-position="actualForm.fpx_rate_type_reference_id === 1? 'prefix': 'suffix'"
                      :currency="actualForm.fpx_rate_type_reference_id === 1? myCurrency: '%'"
                      separator=","
                    />
                    <template v-for="(error, idx) in v$.actualForm.b2b_threshold_charges.$errors">
                      <p
                        :key="'error-'+idx"
                        class="small text-danger"
                      >
                        {{ error.$message }}
                      </p>
                    </template>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="border-bottom pt-1">
                <b-col>
                  <b-form-group>
                    <b-form-checkbox
                      v-model="actualForm.crypto_enable"
                      checked="false"
                      switch
                      inline
                    >
                      Enable Crypto
                    </b-form-checkbox>
                  </b-form-group>
                </b-col>
                <b-col v-if="actualForm.crypto_enable">
                  <b-form-group
                    label="Crypto Charges"
                    label-cols-sm="7"
                  >
                    <vue-numeric
                      v-model="actualForm.crypto_charges"
                      class="form-control form-control-sm text-right"
                      :precision="2"
                      currency-symbol-position="suffix"
                      currency="%"
                      separator=","
                    />
                    <template v-for="(error, idx) in v$.actualForm.crypto_charges.$errors">
                      <p
                        :key="'error-'+idx"
                        class="small text-danger"
                      >
                        {{ error.$message }}
                      </p>
                    </template>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="border-bottom pt-1">
                <b-col>
                  <b-form-group>
                    <b-form-checkbox
                      v-model="actualForm.credit_card_enable"
                      checked="false"
                      switch
                      inline
                    >
                      Enable Credit Card
                    </b-form-checkbox>
                  </b-form-group>
                </b-col>
                <b-col v-if="actualForm.credit_card_enable">
                  <b-form-group
                    label="Credit Card Charges"
                    label-cols-sm="7"
                  >
                    <vue-numeric
                      v-model="actualForm.credit_card_charges"
                      class="form-control form-control-sm text-right"
                      :precision="2"
                      currency-symbol-position="suffix"
                      currency="%"
                      separator=","
                    />
                    <template v-for="(error, idx) in v$.actualForm.credit_card_charges.$errors">
                      <p
                        :key="'error-'+idx"
                        class="small text-danger"
                      >
                        {{ error.$message }}
                      </p>
                    </template>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="border-bottom pt-1">
                <b-col>
                  <b-form-group>
                    <b-form-checkbox
                      v-model="actualForm.paypal_enable"
                      checked="false"
                      switch
                      inline
                    >
                      Enable Paypal
                    </b-form-checkbox>
                  </b-form-group>
                </b-col>
                <b-col v-if="actualForm.paypal_enable">
                  <b-form-group
                    label="Paypal Charges"
                    label-cols-sm="7"
                  >
                    <vue-numeric
                      v-model="actualForm.paypal_charges"
                      class="form-control form-control-sm text-right"
                      :precision="2"
                      currency-symbol-position="suffix"
                      currency="%"
                      separator=","
                    />
                    <template v-for="(error, idx) in v$.actualForm.paypal_charges.$errors">
                      <p
                        :key="'error-'+idx"
                        class="small text-danger"
                      >
                        {{ error.$message }}
                      </p>
                    </template>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="border-bottom pt-1">
                <b-col>
                  <b-form-group>
                    <b-form-checkbox
                      v-model="actualForm.bnpl_enable"
                      checked="false"
                      switch
                      inline
                    >
                      Enable BNPL
                    </b-form-checkbox>
                  </b-form-group>
                </b-col>
                <b-col v-if="actualForm.bnpl_enable">
                  <b-form-group
                    label="BNPL Charges"
                    label-cols-sm="7"
                  >
                    <vue-numeric
                      v-model="actualForm.bnpl_charges"
                      class="form-control form-control-sm text-right"
                      :precision="2"
                      currency-symbol-position="suffix"
                      currency="%"
                      separator=","
                    />
                    <template v-for="(error, idx) in v$.actualForm.bnpl_charges.$errors">
                      <p
                        :key="'error-'+idx"
                        class="small text-danger"
                      >
                        {{ error.$message }}
                      </p>
                    </template>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="border-bottom pt-1 pt-1">
                <b-col>
                  <b-form-group>
                    <b-form-checkbox
                      v-model="actualForm.ewallet_enable"
                      checked="false"
                      switch
                      inline
                    >
                      Enable E-Wallet
                    </b-form-checkbox>
                  </b-form-group>
                </b-col>
                <b-col v-if="actualForm.ewallet_enable">
                  <b-form-group
                    label="E-Wallet Charges"
                    label-cols-sm="7"
                  >
                    <vue-numeric
                      v-model="actualForm.ewallet_charges"
                      class="form-control form-control-sm text-right"
                      :precision="2"
                      currency-symbol-position="suffix"
                      currency="%"
                      separator=","
                    />
                    <template v-for="(error, idx) in v$.actualForm.ewallet_charges.$errors">
                      <p
                        :key="'error-'+idx"
                        class="small text-danger"
                      >
                        {{ error.$message }}
                      </p>
                    </template>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="border-bottom pt-1 pt-1">
                <b-col>
                  <b-form-group>
                    <b-form-checkbox
                      v-model="actualForm.duit_now_enable"
                      checked="false"
                      switch
                      inline
                    >
                      Enable DuitNow
                    </b-form-checkbox>
                  </b-form-group>
                </b-col>
                <b-col v-if="actualForm.duit_now_enable">
                  <b-form-group
                    label="Rate Type (FPX Rate Type)"
                    label-cols-sm="7"
                  >
                    <v-select
                      v-model="actualForm.fpx_rate_type_reference_id"
                      :clearable="false"
                      disabled
                      class="select-size-sm"
                      label="name"
                      :reduce="option => option.value"
                      :options="[{value: 1, name: 'Fixed'}, {value: 2, name: 'Percentage'}]"
                    />
                  </b-form-group>
                  <b-form-group
                    label="DuitNow Charges"
                    label-cols-sm="7"
                  >
                    <vue-numeric
                      v-model="actualForm.duit_now_charges"
                      class="form-control form-control-sm text-right"
                      :precision="2"
                      :currency-symbol-position="actualForm.fpx_rate_type_reference_id === 1? 'prefix': 'suffix'"
                      :currency="actualForm.fpx_rate_type_reference_id === 1? myCurrency: '%'"
                      separator=","
                    />
                    <template v-for="(error, idx) in v$.actualForm.duit_now_charges.$errors">
                      <p
                        :key="'error-'+idx"
                        class="small text-danger"
                      >
                        {{ error.$message }}
                      </p>
                    </template>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="border-bottom pt-1 pt-1">
                <b-col>
                  <b-form-group>
                    <b-form-checkbox
                      disabled
                      checked="true"
                      switch
                      inline
                    >
                      Enable Payout
                    </b-form-checkbox>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group
                    label="FPX Rate Type"
                    label-cols-sm="7"
                  >
                    <v-select
                      v-model="actualForm.payout_rate_type_reference_id"
                      :clearable="false"
                      class="select-size-sm"
                      label="name"
                      :reduce="option => option.value"
                      :options="[{value: 1, name: 'Fixed'}, {value: 2, name: 'Percentage'}]"
                    />
                  </b-form-group>
                  <b-form-group
                    label="Payout Charges"
                    label-cols-sm="7"
                  >
                    <vue-numeric
                      v-model="actualForm.payout_charges"
                      class="form-control form-control-sm text-right"
                      :precision="2"
                      :currency-symbol-position="actualForm.payout_rate_type_reference_id === 1? 'prefix': 'suffix'"
                      :currency="actualForm.payout_rate_type_reference_id === 1? myCurrency: '%'"
                      separator=","
                    />
                    <template v-for="(error, idx) in v$.actualForm.payout_charges.$errors">
                      <p
                        :key="'error-'+idx"
                        class="small text-danger"
                      >
                        {{ error.$message }}
                      </p>
                    </template>
                  </b-form-group>
                  <b-form-group
                    label="Min Payout Charges"
                    label-cols-sm="7"
                  >
                    <vue-numeric
                      v-model="actualForm.min_payout_charges"
                      class="form-control form-control-sm text-right"
                      :precision="2"
                      :currency-symbol-position="actualForm.payout_rate_type_reference_id === 1? 'prefix': 'suffix'"
                      :currency="actualForm.payout_rate_type_reference_id === 1? myCurrency: '%'"
                      separator=","
                    />
                    <template v-for="(error, idx) in v$.actualForm.min_payout_charges.$errors">
                      <p
                        :key="'error-'+idx"
                        class="small text-danger"
                      >
                        {{ error.$message }}
                      </p>
                    </template>
                  </b-form-group>
                  <b-form-group
                    label="Payout Min Amount"
                    label-cols-sm="7"
                  >
                    <vue-numeric
                      v-model="actualForm.payout_min_amount"
                      class="form-control form-control-sm text-right"
                      :precision="2"
                      :currency="myCurrency"
                      separator=","
                    />
                    <template v-for="(error, idx) in v$.actualForm.payout_min_amount.$errors">
                      <p
                        :key="'error-'+idx"
                        class="small text-danger"
                      >
                        {{ error.$message }}
                      </p>
                    </template>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>
      </div>
      <b-row>
        <b-col>
          <div class="settlement-config pb-2">
            <h5 class="mb-1">
              <strong>Settlement Config</strong>
            </h5>
            <b-row>
              <b-col>
                <b-form-group
                  label="Self Settlement"
                  label-cols-sm="7"
                >
                  <b-form-checkbox
                    v-model="actualForm.settlement_enable"
                    checked="false"
                    switch
                    inline
                  >
                    Enable Self Settlement
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <b-form-group
                  label="Settlement Rate Type"
                  label-cols-sm="7"
                >
                  <v-select
                    v-model="actualForm.settlement_rate_type_reference_id"
                    :clearable="false"
                    class="select-size-sm"
                    label="name"
                    :reduce="option => option.value"
                    :options="[{value: 1, name: 'Fixed'}, {value: 2, name: 'Percentage'}]"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  label="Settlement Charges"
                  label-cols-sm="7"
                >
                  <vue-numeric
                    v-model="actualForm.settlement_charges"
                    class="form-control form-control-sm text-right"
                    :precision="2"
                    :currency-symbol-position="actualForm.settlement_rate_type_reference_id === 1? 'prefix': 'suffix'"
                    :currency="actualForm.settlement_rate_type_reference_id === 1? myCurrency: '%'"
                    separator=","
                  />
                  <template v-for="(error, idx) in v$.actualForm.settlement_charges.$errors">
                    <p
                      :key="'error-'+idx"
                      class="small text-danger"
                    >
                      {{ error.$message }}
                    </p>
                  </template>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
          <div class="charges pb-2">
            <h5 class="mb-1">
              <strong>Other Charges</strong>
            </h5>
            <b-row>
              <b-col>

                <!--                <b-form-group-->
                <!--                  label="Min Payout Charges"-->
                <!--                  label-cols-sm="7"-->
                <!--                >-->
                <!--                  <vue-numeric-->
                <!--                    v-model="actualForm.min_payout_charges"-->
                <!--                    class="form-control form-control-sm text-right"-->
                <!--                    :precision="2"-->
                <!--                    :currency="myCurrency"-->
                <!--                    separator=","-->
                <!--                  />-->
                <!--                  <template v-for="(error, idx) in v$.actualForm.min_payout_charges.$errors">-->
                <!--                    <p-->
                <!--                      :key="'error-'+idx"-->
                <!--                      class="small text-danger"-->
                <!--                    >-->
                <!--                      {{ error.$message }}-->
                <!--                    </p>-->
                <!--                  </template>-->
                <!--                </b-form-group>-->
                <!--                <b-form-group-->
                <!--                  label="Payout Charges"-->
                <!--                  label-cols-sm="7"-->
                <!--                >-->
                <!--                  <vue-numeric-->
                <!--                    v-model="actualForm.payout_charges"-->
                <!--                    class="form-control form-control-sm text-right"-->
                <!--                    :precision="2"-->
                <!--                    :currency="myCurrency"-->
                <!--                    separator=","-->
                <!--                  />-->
                <!--                  <template v-for="(error, idx) in v$.actualForm.payout_charges.$errors">-->
                <!--                    <p-->
                <!--                      :key="'error-'+idx"-->
                <!--                      class="small text-danger"-->
                <!--                    >-->
                <!--                      {{ error.$message }}-->
                <!--                    </p>-->
                <!--                  </template>-->
                <!--                </b-form-group>-->

                <b-form-group
                  label="Min Fund Charges"
                  label-cols-sm="7"
                >
                  <vue-numeric
                    v-model="actualForm.min_fund_charges"
                    class="form-control form-control-sm text-right"
                    :precision="2"
                    :currency="myCurrency"
                    separator=","
                  />
                  <template v-for="(error, idx) in v$.actualForm.min_fund_charges.$errors">
                    <p
                      :key="'error-'+idx"
                      class="small text-danger"
                    >
                      {{ error.$message }}
                    </p>
                  </template>
                </b-form-group>
                <b-form-group
                  label="Max Fund Charges"
                  label-cols-sm="7"
                >
                  <vue-numeric
                    v-model="actualForm.max_fund_charges"
                    class="form-control form-control-sm text-right"
                    :precision="2"
                    :currency="myCurrency"
                    separator=","
                  />
                  <template v-for="(error, idx) in v$.actualForm.max_fund_charges.$errors">
                    <p
                      :key="'error-'+idx"
                      class="small text-danger"
                    >
                      {{ error.$message }}
                    </p>
                  </template>
                </b-form-group>
                <b-form-group
                  label="Whatsapp Charges"
                  label-cols-sm="7"
                >
                  <vue-numeric
                    v-model="actualForm.whatsapp_charges"
                    class="form-control form-control-sm text-right"
                    :precision="2"
                    :currency="myCurrency"
                    separator=","
                  />
                  <template v-for="(error, idx) in v$.actualForm.whatsapp_charges.$errors">
                    <p
                      :key="'error-'+idx"
                      class="small text-danger"
                    >
                      {{ error.$message }}
                    </p>
                  </template>
                </b-form-group>
                <b-form-group
                  label="SMS Charges"
                  label-cols-sm="7"
                >
                  <vue-numeric
                    v-model="actualForm.sms_charges"
                    class="form-control form-control-sm text-right"
                    :precision="2"
                    :currency="myCurrency"
                    separator=","
                  />
                  <template v-for="(error, idx) in v$.actualForm.sms_charges.$errors">
                    <p
                      :key="'error-'+idx"
                      class="small text-danger"
                    >
                      {{ error.$message }}
                    </p>
                  </template>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
        </b-col>
        <b-col
          cols="5"
          offset="2"
        >
          <div class="entitlements pb-2">
            <h5 class="mb-1">
              <strong>Entitlements</strong>
            </h5>
            <b-row>
              <b-col>
                <b-form-group
                  label="Short URL"
                  label-cols-sm="7"
                >
                  <vue-numeric
                    v-model="actualForm.short_url"
                    class="form-control form-control-sm text-right"
                    separator=","
                  />
                  <template v-for="(error, idx) in v$.actualForm.short_url.$errors">
                    <p
                      :key="'error-'+idx"
                      class="small text-danger"
                    >
                      {{ error.$message }}
                    </p>
                  </template>
                </b-form-group>
                <b-form-group
                  label="Store"
                  label-cols-sm="7"
                >
                  <vue-numeric
                    v-model="actualForm.store"
                    class="form-control form-control-sm text-right"
                    separator=","
                  />
                  <template v-for="(error, idx) in v$.actualForm.store.$errors">
                    <p
                      :key="'error-'+idx"
                      class="small text-danger"
                    >
                      {{ error.$message }}
                    </p>
                  </template>
                </b-form-group>
                <b-form-group
                  label="Payment Form"
                  label-cols-sm="7"
                >
                  <vue-numeric
                    v-model="actualForm.payment_form"
                    class="form-control form-control-sm text-right"
                    separator=","
                  />
                  <template v-for="(error, idx) in v$.actualForm.payment_form.$errors">
                    <p
                      :key="'error-'+idx"
                      class="small text-danger"
                    >
                      {{ error.$message }}
                    </p>
                  </template>
                </b-form-group>
                <b-form-group
                  label="Catalog"
                  label-cols-sm="7"
                >
                  <vue-numeric
                    v-model="actualForm.catalog"
                    class="form-control form-control-sm text-right"
                    separator=","
                  />
                  <template v-for="(error, idx) in v$.actualForm.catalog.$errors">
                    <p
                      :key="'error-'+idx"
                      class="small text-danger"
                    >
                      {{ error.$message }}
                    </p>
                  </template>
                </b-form-group>
                <b-form-group
                  label="Bill Form"
                  label-cols-sm="7"
                >
                  <vue-numeric
                    v-model="actualForm.bill_form"
                    class="form-control form-control-sm text-right"
                    separator=","
                  />
                  <template v-for="(error, idx) in v$.actualForm.bill_form.$errors">
                    <p
                      :key="'error-'+idx"
                      class="small text-danger"
                    >
                      {{ error.$message }}
                    </p>
                  </template>
                </b-form-group>
                <b-form-group
                  label="API"
                  label-cols-sm="7"
                >
                  <vue-numeric
                    v-model="actualForm.api"
                    class="form-control form-control-sm text-right"
                    separator=","
                  />
                  <template v-for="(error, idx) in v$.actualForm.api.$errors">
                    <p
                      :key="'error-'+idx"
                      class="small text-danger"
                    >
                      {{ error.$message }}
                    </p>
                  </template>
                </b-form-group>
                <b-form-group
                  label="Domain"
                  label-cols-sm="7"
                >
                  <vue-numeric
                    v-model="actualForm.domain"
                    class="form-control form-control-sm text-right"
                    separator=","
                  />
                  <template v-for="(error, idx) in v$.actualForm.domain.$errors">
                    <p
                      :key="'error-'+idx"
                      class="small text-danger"
                    >
                      {{ error.$message }}
                    </p>
                  </template>
                </b-form-group>
                <b-form-group
                  label="Virtual Account"
                  label-cols-sm="7"
                >
                  <vue-numeric
                    v-model="actualForm.virtual_account"
                    class="form-control form-control-sm text-right"
                    separator=","
                  />
                  <template v-for="(error, idx) in v$.actualForm.virtual_account.$errors">
                    <p
                      :key="'error-'+idx"
                      class="small text-danger"
                    >
                      {{ error.$message }}
                    </p>
                  </template>
                </b-form-group>
                <b-form-group
                  label="Product"
                  label-cols-sm="7"
                >
                  <vue-numeric
                    v-model="actualForm.product"
                    class="form-control form-control-sm text-right"
                    separator=","
                  />
                  <template v-for="(error, idx) in v$.actualForm.product.$errors">
                    <p
                      :key="'error-'+idx"
                      class="small text-danger"
                    >
                      {{ error.$message }}
                    </p>
                  </template>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import { isEmpty } from 'lodash'
import {
  required, requiredIf, minValue,
} from '@vuelidate/validators'
import vSelect from 'vue-select'
import { useVuelidate } from '@vuelidate/core'
import Config from '@/views/administration/plans/formConfig'

export default {
  setup: () => ({ v$: useVuelidate() }),
  components: {
    vSelect,
  },
  data() {
    return {
      actualForm: {
        plan_name: '',
        record_status: false,
      },

      temp: [],
      formMode: 'create',
    }
  },
  validations() {
    return {
      actualForm: {
        plan_name: { required },
        description: { },
        fpx_charges: { minValue: minValue(0) },
        fpx_b2b_charges: { minValue: minValue(0) },
        crypto_charges: { minValue: minValue(0) },
        credit_card_charges: { minValue: minValue(0) },
        paypal_charges: { minValue: minValue(0) },
        bnpl_charges: { minValue: minValue(0) },
        duit_now_charges: { minValue: minValue(0) },
        ewallet_charges: { minValue: minValue(0) },
        plan_charges: { minValue: minValue(0) },
        threshold_charges: { minValue: minValue(0) },
        b2b_threshold_charges: { minValue: minValue(0) },
        min_payout_charges: { minValue: minValue(0) },
        payout_min_amount: { minValue: minValue(0) },
        payout_charges: { minValue: minValue(0) },
        min_fund_charges: { minValue: minValue(0) },
        max_fund_charges: { minValue: minValue(0) },
        whatsapp_charges: { minValue: minValue(0) },
        sms_charges: { minValue: minValue(0) },
        short_url: { minValue: minValue(0) },
        store: { minValue: minValue(0) },
        payment_form: { minValue: minValue(0) },
        settlement_charges: { minValue: minValue(0) },
        catalog: { minValue: minValue(0) },
        bill_form: { minValue: minValue(0) },
        api: { minValue: minValue(0) },
        domain: { minValue: minValue(0) },
        virtual_account: { minValue: minValue(0) },
        product: { minValue: minValue(0) },
      },
    }
  },
  computed: {
    templateList() {
      const list = this.$store.getters['subscriptionsPlan/getTemplateList']
      return list
    },

    templateListOpts() {
      const list = this.$store.getters['subscriptionsPlan/getTemplateList']
      const array = []
      list.forEach(item => array.push({ id: item.id, planName: item.plan_name }))
      return array
    },

    parentPageNamedRoute() {
      return this.$route.matched[0].name
    },
    formTitle() {
      const title = this.formConfig.title[this.formMode]
      return title
    },
    formConfig() {
      if (Config) {
        return Config.formData()
      }
      return {}
    },
  },
  async mounted() {
    this.isBusy = false

    if (!isEmpty(this.$route.params)) {
      this.formMode = this.$route.params.mode
    }

    if (this.formMode === 'edit') {
      const breadcrumbUpdatePayload = [
        { title: 'Plans' },
        { title: 'Edit Plan' },
      ]
      await this.$store.dispatch('breadcrumbs/setBreadcrumb', breadcrumbUpdatePayload)

      this.$store.dispatch('subscriptionsPlan/getSubscriptionPlanById', this.$route.params.id).then(({ data }) => {
        this.actualForm = data.data
      })
    } else if (this.formMode === 'create') {
      const breadcrumbUpdatePayload = [
        { title: 'Plans' },
        { title: 'Create Plan' },
      ]
      await this.$store.dispatch('breadcrumbs/setBreadcrumb', breadcrumbUpdatePayload)
      console.log(`${this.formMode} Mode`)
    } else {
      console.log('Unknown Mode')
      const breadcrumbUpdatePayload = [
        { title: 'Plans' },
      ]
      await this.$store.dispatch('breadcrumbs/setBreadcrumb', breadcrumbUpdatePayload)
      await this.$router.push({ name: this.parentPageNamedRoute })
    }
  },
  methods: {
    loadTemplate(id) {
      this.$store.dispatch('subscriptionsPlan/getSubscriptionPlanById', id).then(({ data }) => {
        this.actualForm = data.data
        this.actualForm.plan_name = ''

        delete this.actualForm.id
        delete this.actualForm.created_at
        delete this.actualForm.updated_at
      })
    },
    fetchTemplate() {
      const payload = {
        limit: 10,
        skip: 0,
        record_status: 1,
        search: {
          search_enable: false,
          search_key: '',
          search_column: '',
          search_replace_word_enable: true,
          search_word_replace: ' ',
          search_word_replace_to: '_',
        },
        sort: {
          parameter_name: 'created_at',
          sort_type: this.sortDesc ? 'desc' : 'asc',
        },
      }
      this.$store.dispatch('subscriptionsPlan/getSubscriptions', payload)
    },
    async onSubmit() {
      const valid = this.v$.actualForm.$validate()

      if (!valid) {
        return
      }

      if (
        this.actualForm.weight_rank === 1
          || this.actualForm.weight_rank === 2
          || this.actualForm.weight_rank === 3
      ) {
        this.actualForm.weight_rank = 4
      }

      const payload = {
        ...this.actualForm,
      }

      console.log('valid')
      if (this.formMode === 'create') {
        await this.$store.dispatch('subscriptionsPlan/createSubscriptions', payload).then(data => {
          if (data.data.response_code === 2000) {
            this.$router.push({ name: this.parentPageNamedRoute })
          }
        })
      } else if (this.formMode === 'edit') {
        const newPayload = {
          ...payload,
          payloadId: this.actualForm.id,
        }

        await this.$store.dispatch('subscriptionsPlan/updateSubscriptions', newPayload).then(data => {
          if (data.data.response_code === 2000) {
            this.$router.push({ name: this.parentPageNamedRoute })
          }
        })
      }
    },
    cancelForm() {
      this.$router.push({ name: this.parentPageNamedRoute })
    },
  },
}
</script>
<style lang="scss" scoped>
.has-error {
  .form-control.form-control-sm { border-color: #ED4C67;}
}
</style>
